import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import gsap from 'gsap'

import Intro from '../components/Intro'

const About = () => {
  const [isItTime, setTime] = useState(false)
  setTimeout(() => setTime(true), 2000)
  const tl = gsap.timeline()

  useEffect(() => {
    if (isItTime) {
      setTimeout(() => navigate('/'), 1000)
      console.log('stated')
      tl
        .to('.intro-overlay', { css: { display: 'block' } })
        .to('.overlay', {
          duration: 1.6,
          height: '100%',
          ease: 'expo.inOut',
          stagger: 0.4
        })
    } else {
      tl
        .to('.overlay', {
          duration: 0,
          height: 0,
          ease: 'expo.inOut'
        })
        .to('.intro-overlay', { css: { display: 'none' } })
    }
  }, [isItTime])

  return (
    <>
      <Intro />
      <div className="page">
        <div className="container">
          <div className="content">
            <h1>Thanks for getting in touch!</h1>
            <h3>We will get back to you as soon as possible.</h3>
            <p>You will be redirected shortly...</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
